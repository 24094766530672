<template>
  <div class="auth d-flex justify-content-center align-items-center" >
    <b-container fluid="sm">
      <b-row align-content="center" align-h="center" align-v="center">
        <b-alert  show variant="danger">
          <h4 class="alert-heading">401</h4>
          <p>
            Acesso não autorizado
          </p>
          <hr>
          <p class="mb-0">
            Suas credenciais de acesso não foram aprovadas, por favor, tente novamente mais tarde.
          </p>
        </b-alert>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AccessDenied"
}
</script>

<style scoped>
  .auth {
    height: 100vh !important;
  }
</style>